html,
body,
#root,
.App {
  height: 100%;
  margin: 0;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */

}

html, body {position: fixed; left: 0; top: 0; right: 0; bottom: 0; touch-action: none;}

.App {
  text-align: center;
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: white;
}

.p-button.p-button-icon-only {
  width: 2rem !important;
  height: 2rem !important;
  margin-left: 2px;
}

.datePicker>div>input {
  text-align: center;
}

.App .header {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.p-toolbar {
  padding: 0.5em !important;
}

h2 {
  line-height: 0.5em;
}

.App .content {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 100%;
}

.App .footer {
  flex: 0 1 40px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}